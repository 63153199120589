<template>
  <div>
    <v-card class="px-2">
      <v-card-title>
        <div class="font-weight-medium">
          <span class="secondary-font">{{ $t("account.api.title") }}</span>
        </div>
      </v-card-title>
      <v-card-subtitle class="grey--text text--darken-1">{{ $t("account.api.description") }}</v-card-subtitle>
      <v-card-text class="mt-4">
        <div class="mb-1 font-weight-bold">{{ $t('security.fields.api_key.description') }}</div>
        <v-text-field
          class="font-weight-bold"
          :placeholder="$i18n.t('security.fields.api_key.title')"
          :value="$auth.user().apiKey"
          autocomplete="off"
          outlined
          dense
          append-icon="mdi-content-copy"
          @click:append="copyApiKey()"
          readonly
          hide-details
        ></v-text-field>
        <div class="mb-1 mt-2 font-weight-bold">{{ $t('security.fields.secret.description') }}</div>
        <v-text-field
          class="font-weight-bold"
          :placeholder="$i18n.t('security.fields.secret.title')"
          :value="form.secret"
          autocomplete="off"
          outlined
          dense
          append-icon="mdi-content-copy"
          @click:append="copySecret()"
          readonly
        ></v-text-field>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  created() {
    this.form.secret = this.$auth.user().secret;
  },
  data: () => ({
    form: {
      secret: null
    }
  }),
  methods: {
    copyApiKey() {
      const user = this.$auth.user();
      this.$copyText(user.apiKey).then(() => {
        this.notify({
          message: this.$t("account.api.copy.api_key"),
          timeout: 2000
        });
      });
    },
    copySecret() {
      const user = this.$auth.user();
      this.$copyText(user.secret).then(() => {
        this.notify({
          message: this.$t("account.api.copy.secret"),
          timeout: 2000
        });
      });
    },
    ...mapActions({ request: "request", notify: "notification/notify" })
  }
};
</script>